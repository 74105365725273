import { FC } from 'react';

import Image from 'next/image';

import pagLeeLogoImg from '~/shared/assets/images/pag-lee-logo.png';

import { Container, LogoWrapper } from './styles';

interface IAuthHeaderProps {
  title: string;
  subtitle: string;
  customLogo?: string;
}

export const AuthHeader: FC<IAuthHeaderProps> = ({
  title,
  subtitle,
  customLogo,
}) => {
  return (
    <>
      <LogoWrapper>
        <Image
          src={customLogo || pagLeeLogoImg}
          alt="PagLee"
          width={200}
          height={60}
        />
      </LogoWrapper>

      <Container>
        <h1>{title}</h1>

        <p>{subtitle}</p>
      </Container>
    </>
  );
};
