import { HtmlHTMLAttributes, ReactNode } from 'react';

import { FiInfo } from 'react-icons/fi';

import { WithChildren } from '~/shared/types/WithChildren';
import { IMarginProps } from '~/shared/interfaces/IMarginProps';

import { Container, HintWrapper } from './styles';

import { Tooltip } from '../../Tooltip';

interface ILabelProps
  extends IMarginProps,
    HtmlHTMLAttributes<HTMLSpanElement> {
  hint?: ReactNode;
}

export const Label: WithChildren<ILabelProps> = ({
  children,
  hint,
  m,
  mb,
  mh,
  ml,
  mr,
  mt,
  mv,
  ...props
}) => {
  return (
    <Container m={m} mb={mb} mh={mh} ml={ml} mr={mr} mt={mt} mv={mv} {...props}>
      {children}

      {!!hint && (
        <Tooltip label={hint}>
          <HintWrapper>
            <FiInfo />
          </HintWrapper>
        </Tooltip>
      )}
    </Container>
  );
};
