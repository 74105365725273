import {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import { Control, Controller, FieldError } from 'react-hook-form';

import { Input, IInputProps } from '../Input';

interface IControlledInputProps extends Omit<IInputProps, 'error'> {
  control: Control<any, any>;
  name: string;
  error?: FieldError;
  disableHookFormOnChange?: boolean;
  mask?: (value: string, ...args: any) => string;
}

const BaseControlledInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IControlledInputProps
> = (
  { control, name, error, onChange, disableHookFormOnChange, mask, ...props },
  ref
) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: fieldOnChange, value },
        formState: { errors },
      }) => {
        const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
          const newEvent = { ...event };

          if (mask) {
            newEvent.target.value = mask(newEvent.target.value);
          }

          onChange?.(newEvent);

          if (!disableHookFormOnChange) fieldOnChange(newEvent);
        };

        return (
          <Input
            {...props}
            name={name}
            ref={ref}
            value={props.value || value || ''}
            error={error?.message || (errors?.[name]?.message as string) || ''}
            onChange={handleChange}
          />
        );
      }}
    />
  );
};

export const ControlledInput = forwardRef(BaseControlledInput);
