import { FC } from 'react';

import { IconType } from 'react-icons';

import { Container } from './styles';

interface IAuthLinkProps {
  icon: IconType;
  title: string;
  href: string;
}

export const AuthLink: FC<IAuthLinkProps> = ({ href, icon: Icon, title }) => {
  return (
    <Container href={href}>
      <Icon />

      <span>{title}</span>
    </Container>
  );
};
