import styled, { keyframes, ThemeColors } from 'styled-components';

interface ILoaderProps {
  color?: ThemeColors;
  size?: number;
}

const spinAnimation = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const Loader = styled.span<ILoaderProps>`
  display: inline-block;

  min-width: ${({ size }) => (typeof size !== 'undefined' ? size : 16)}px;
  max-width: ${({ size }) => (typeof size !== 'undefined' ? size : 16)}px;
  min-height: ${({ size }) => (typeof size !== 'undefined' ? size : 16)}px;
  max-height: ${({ size }) => (typeof size !== 'undefined' ? size : 16)}px;
  border: ${({ theme, color = 'onPrimary' }) =>
    `1.5px solid ${theme.colors[color]}`};
  border-top-color: transparent;
  border-radius: 50%;

  animation: ${spinAnimation} 0.6s linear infinite;
`;
