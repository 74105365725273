import styled from 'styled-components';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

export const Container = styled.span<IMarginProps>`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
  font-size: 12px;
  width: 100%;
  text-align: left;
  width: max-content;

  letter-spacing: 0.5px;

  ${marginPropsStyles}
`;

export const HintWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
