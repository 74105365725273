import styled from 'styled-components';

export const LogoWrapper = styled.div`
  max-width: 280px;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  > img {
    object-fit: contain;
    object-position: center;
  }
`;

export const Container = styled.header`
  margin-top: ${({ theme }) =>
    `calc(${theme.spacing.lg} + ${theme.spacing.lg})`};

  > h1 {
    line-height: 36px;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.onSurface};
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[300]};
  }
`;
