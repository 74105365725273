import Link from 'next/link';

import styled from 'styled-components';

export const Container = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.gray[600]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xs};
  width: 100%;

  transition: opacity 0.3s ease-in;

  > svg {
    width: 13px;
    height: 13px;
    stroke-width: 3px;
  }

  > span {
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
  }

  &:hover {
    opacity: 0.65;
  }
`;
